import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Logo from '../components/Logo'

const IndexPage = () => (
  <Layout>
    <SEO title="admminity" />
    <h1 className="title">
      <Logo />
    </h1>
  </Layout>
)

export default IndexPage
